import { useEffect, useState } from "react";
import "./Carousel.css";
import { CaretLeft, CaretRight } from "phosphor-react";

function Carousel({
  images,
  withNav,
  withAutomaticTransition,
  withArrows,
  additionalClasses,
}) {
  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const [intervalID, setIntervalID] = useState(null);

  const stopAutomaticTransition = () => {
    if (intervalID) {
      clearInterval(intervalID);
      setIntervalID(null);
    }
  };

  const goToPrevGalleryIndex = () => {
    setCurrentGalleryIndex((curr) =>
      curr === 0 ? images.length - 1 : curr - 1
    );

    stopAutomaticTransition();
  };

  const goToNextGalleryIndex = () => {
    setCurrentGalleryIndex((curr) =>
      curr === images.length - 1 ? 0 : curr + 1
    );

    stopAutomaticTransition();
  };

  const handleTabClick = (index) => {
    setCurrentGalleryIndex(index);

    stopAutomaticTransition();
  };

  useEffect(() => {
    if (!withAutomaticTransition) return;

    const intervalID = setInterval(goToNextGalleryIndex, 3000);
    setIntervalID(intervalID);
    return () => clearInterval(intervalID);
  }, []);

  return (
    <div
      className={
        "carousel" + (additionalClasses ? ` ${additionalClasses}` : "")
      }
    >
      <div className="carousel-image-container">
        {withArrows && (
          <span className="carousel-arrow" onClick={goToPrevGalleryIndex}>
            <CaretLeft size={24} weight="bold" />
          </span>
        )}

        <ul className="carousel-image-list">
          {images.map((image, index) => (
            <li key={image}>
              <img
                src={image}
                className={
                  currentGalleryIndex === index
                    ? "carousel-image active"
                    : "carousel-image"
                }
                alt={`Снимка ${image}}`}
              />
            </li>
          ))}
        </ul>

        {withArrows && (
          <span className="carousel-arrow" onClick={goToNextGalleryIndex}>
            <CaretRight size={24} weight="bold" />
          </span>
        )}
      </div>

      {withNav && (
        <nav className="carousel-nav">
          {images.map((image, index) => (
            <span
              key={image}
              className={
                currentGalleryIndex === index
                  ? "carousel-nav-tab active"
                  : "carousel-nav-tab"
              }
              role="tab"
              aria-selected="true"
              onClick={() => handleTabClick(index)}
            />
          ))}
        </nav>
      )}
    </div>
  );
}

export default Carousel;
