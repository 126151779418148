import "./Navigation.css";

function Navigation({
  links,
  isStickyTop,
  isCentered,
  withLogoLink,
  withCta,
  onCtaClick,
  additionalClasses,
}) {
  const logo = (
    <img
      src="/logo-with-heading.png"
      className="nav-logo"
      alt="QR Подаръци лого със заглавие"
    />
  );

  const navClassName =
    "nav" +
    (isStickyTop ? " nav-sticky-top" : "") +
    (isCentered ? " nav-centered" : "") +
    (additionalClasses ? ` ${additionalClasses}` : "");

  return (
    <nav className={navClassName}>
      {withLogoLink ? (
        <a href="/" target="_blank">
          {logo}
        </a>
      ) : (
        logo
      )}

      {(links || withCta) && (
        <ul className="nav-links-list">
          {links &&
            links.map((link, index) => (
              <li key={index}>
                <a className="nav-link" href={link.url}>
                  {link.text}
                </a>
              </li>
            ))}
          {withCta && (
            <li key={-1}>
              <button className="nav-button" onClick={onCtaClick}>
                Поръчай сега
              </button>
            </li>
          )}
        </ul>
      )}
    </nav>
  );
}

export default Navigation;
