import "./Home.css";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import Navigation from "./Navigation";
import Carousel from "./Carousel";
import { useRef } from "react";
import {
  EnvelopeSimple,
  FacebookLogo,
  InstagramLogo,
  Phone,
  TiktokLogo,
} from "phosphor-react";

const homeFortunesKeychainsSection = "#home-fortunes-keychains-section";
const homeFactsKeychainsSection = "#home-facts-keychains-section";
const homeMugsSection = "#home-mugs-section";
const homeOrdersSection = "#home-orders-section";

function Home() {
  const fortuneKeychainsRef = useRef(null);
  const factsKeychainsRef = useRef(null);
  const mugsRef = useRef(null);
  const ordersRef = useRef(null);

  const navigateToRef = (ref, block = "center") => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: block,
    });
  };

  const navigateToFortunesKeychainsRef = () => {
    navigateToRef(fortuneKeychainsRef, "center");
    window.history.pushState({}, "", homeFortunesKeychainsSection);
  };

  const navigateToMugsRef = () => {
    navigateToRef(mugsRef, "center");
    window.history.pushState({}, "", homeMugsSection);
  };

  const navigateToOrdersRef = () => {
    navigateToRef(ordersRef, "center");
    window.history.pushState({}, "", homeOrdersSection);
  };

  const homeHeaderCarouselImages = [
    "/keychains-many-1.webp",
    "/mugs-koko-1.webp",
    "/keychains-many-2.webp",
    "/mugs-koko-3.webp",
    "/mugs-fu-1.webp",
    "/mugs-fu-2.webp",
  ];

  const homeMugsSectionCarouselImages = [
    "/mugs-koko-1.webp",
    "/mugs-koko-3.webp",
    "/mugs-fu-1.webp",
    "/mugs-fu-2.webp",
  ];

  return (
    <div className="home-container">
      <Helmet>
        <title>Уникални подаръци – ключодържатели и чаши с QR код</title>

        <meta
          name="description"
          content="Открийте нови и уникални идеи за подаръци – иновативни ключодържатели и чаши с QR код. Сканирайте QR кода всеки ден за ново късметче или интересен факт!"
        />

        <link rel="canonical" href="https://qr-gifts.teonibyte.com/" />
        <link
          rel="alternate"
          hreflang="bg"
          href="https://qr-gifts.teonibyte.com/"
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href="https://qr-gifts.teonibyte.com/"
        />
      </Helmet>

      <Navigation
        withCta
        onCtaClick={navigateToOrdersRef}
        links={[
          {
            url: homeFortunesKeychainsSection,
            text: "Ключодържатели",
          },
          {
            url: homeMugsSection,
            text: "Чаши",
          },
        ]}
      />

      <header className="home-header">
        <article className="home-article">
          <h1>Уникални подаръци за всеки повод</h1>

          <p>
            Ключодържатели и чаши с необикновени дизайни на животни с QR код,
            който носи скрити изненади. Сканирайте QR кода всеки ден за
            ежедневна доза късмет и любопитни факти!
          </p>

          <Carousel
            images={homeHeaderCarouselImages}
            withNav
            withAutomaticTransition
            additionalClasses={"small-screen-only"}
            withArrows
          />

          <a
            className="button-link home-cta-button"
            href={homeFortunesKeychainsSection}
          >
            Разгледайте колекцията
          </a>
        </article>

        <Carousel
          images={homeHeaderCarouselImages}
          withNav
          withAutomaticTransition
          additionalClasses={"large-screen-only"}
          withArrows
        />
      </header>

      <section
        className="home-section"
        ref={fortuneKeychainsRef}
        id="home-fortunes-keychains-section"
      >
        <article className="home-article">
          <h2>Оригинални ключодържатели с QR код и късметче на деня</h2>

          <p>
            Запознайте се с Коко, Фу и Оливър – вашите нови приятели, чиято цел
            е да носят ежедневни усмивки и късмет! Сканирайте техния QR код и
            открийте различно късметче за деня ви – вдъхновение, забавление или
            позитивно послание, подготвено специално за вашия ден! Перфектна
            идея за подарък – уникален, персонален и зареден с настроение!
          </p>

          <ul className="home-cards-list">
            <li className="home-card">
              <img
                className="home-card-image"
                src="/keychains-koko.webp"
                alt="Коко"
              />
            </li>
            <li className="home-card">
              <img
                className="home-card-image"
                src="/keychains-fu.webp"
                alt="Фу"
              />
            </li>
            <li className="home-card">
              <img
                className="home-card-image"
                src="/keychains-oliver.webp"
                alt="Оливър"
              />
            </li>
            <li className="home-card small-screen-only">
              <img
                className="home-card-image"
                src="/keychains-many-3.webp"
                alt="Коко, Фу и Оливър"
              />
            </li>
          </ul>

          <a
            className="button-link home-cta-button streched"
            href={homeFactsKeychainsSection}
          >
            Вижте още
          </a>
        </article>
      </section>

      <section
        className="home-section"
        ref={factsKeychainsRef}
        id="home-facts-keychains-section"
      >
        <img
          className="home-section-image large-screen-only"
          src="/keychains-piros.webp"
          alt="Пирос"
        />

        <article className="home-article">
          <h2>Ключодържател с QR код и образователен факт на деня</h2>

          <p>
            Представяме ви Пирос – вашият умен спътник, който всеки ден разкрива
            нещо ново и интересно! Сканирайте неговия QR код и обогатете
            знанията си със забавни и образователни факти. Оригинален подарък,
            който вдъхновява и изненадва всеки ден!
          </p>

          <ul className="home-cards-list grid-small-screen-only">
            <li className="home-card">
              <img
                className="home-card-image"
                src="/keychains-piros.webp"
                alt="Пирос отпред"
              />
            </li>
            <li className="home-card">
              <img
                className="home-card-image"
                src="/keychains-piros-back.webp"
                alt="Пирос отзад"
              />
            </li>
          </ul>

          <a className="button-link home-cta-button" href={homeMugsSection}>
            Вижте и нашите чаши
          </a>
        </article>
      </section>

      <section className="home-section" ref={mugsRef} id="home-mugs-section">
        <article className="home-article">
          <h2>Иновативни чаши с QR код и изненада всеки ден</h2>

          <p>
            Какво по-хубаво от чаша ароматно кафе или топъл чай, съчетани с
            вдъхновение? Нашите чаши с QR код са перфектния избор за оригинален
            подарък за вашите близки – сканирайте кода всеки ден и открийте ново
            късметче или интересен факт.
          </p>

          <Carousel
            images={homeMugsSectionCarouselImages}
            withAutomaticTransition
            withNav
            withArrows
            additionalClasses={"small-screen-only"}
          />

          <a className="button-link home-cta-button" href={homeOrdersSection}>
            Информация за поръчки
          </a>
        </article>

        <Carousel
          images={homeMugsSectionCarouselImages}
          withAutomaticTransition
          withNav
          withArrows
          additionalClasses={"large-screen-only"}
        />
      </section>

      <section
        className="home-section home-mini-section"
        ref={ordersRef}
        id="home-orders-section"
      >
        <article className="home-article">
          <h3>Поръчайте своя уникален подарък</h3>

          <ul className="home-price-list">
            <li
              className="badge pointer"
              onClick={navigateToFortunesKeychainsRef}
            >
              Kлючодържатели - 12.00 лв.
            </li>
            <li className="badge pointer" onClick={navigateToMugsRef}>
              Чаши - 23.99 лв.
            </li>
          </ul>

          <p>
            Свържете се с нас чрез <strong>Facebook</strong>,{" "}
            <strong>Instagram</strong> или <strong>TikTok</strong> за поръчки и
            заявки чрез следните линкове:
          </p>
          <div className="footer-social-icons">
            <a
              href="https://www.facebook.com/people/QR-%D0%9F%D0%BE%D0%B4%D0%B0%D1%80%D1%8A%D1%86%D0%B8/61560692463792/"
              target="_blank"
            >
              <FacebookLogo size={32} />
            </a>
            <a
              href="https://www.instagram.com/quickresponsegifts"
              target="_blank"
            >
              <InstagramLogo size={32} />
            </a>
            <a href="https://www.tiktok.com/@qr.gifts0" target="_blank">
              <TiktokLogo size={32} />
            </a>
          </div>
          <p>
            За въпроси или нужда от допълнителна информация можете да се
            свържете с нас чрез обаждане или имейл:
          </p>
          <div className="footer-social-icons">
            <a href="tel:+359877277071" target="_blank">
              <Phone size={32} />
            </a>
            <a
              href="mailto:admin@teonibyte.com?subject=Въпрос%20към%20екипа%20на%20QR%20подаръци"
              target="_blank"
            >
              <EnvelopeSimple size={32} />
            </a>
          </div>
          <p>
            <strong>Следвайте ни в социалните мрежи</strong> - разгледайте още
            снимки и видеа на нашите оригинални подаръци и бъдете в течение с
            всички нови предложения и промоции!
          </p>
        </article>
      </section>

      <Footer />
    </div>
  );
}

export default Home;
