import React, { useEffect } from "react";
import Footer from "./Footer";
import { useForm } from "react-hook-form";
import { Asterisk, Lock } from "phosphor-react";
import { RotatingLines } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import Navigation from "./Navigation";
import { Helmet } from "react-helmet";

function Access() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    criteriaMode: "all",
  });

  const [formSubmitError, setFormSubmitError] = React.useState(null);
  const [formSuccessMessage, setFormSuccessMessage] = React.useState(null);
  const [shouldAttemptAutoSubmit, setShouldAttemptAutoSubmit] =
    React.useState(false);

  const params = useParams();
  const qrCodeID = params.qrCodeID;

  useEffect(() => {
    fetch(`/api/qr-codes/${qrCodeID}/config`, {
      method: "GET",
    })
      .then((resp) => {
        if (!resp.ok) {
          setShouldAttemptAutoSubmit(true);

          if (resp.status === 401) {
            // user is unauthorized, needs to input new access code
            return;
          }

          throw new Error(
            "Неочаквана грешка при зареждане на страницата. Моля, опитайте отново или се свържете с нас за съдействие."
          );
        }

        // user is authorized, redirect to content page
        window.location.pathname = `/api/qr-codes/${qrCodeID}/content`;
      })
      .catch((err) => {
        setFormSubmitError(err?.message || err || "Неочаквана грешка.");
        setShouldAttemptAutoSubmit(true);
      });
  }, [qrCodeID]);

  const onSubmit = (data) => {
    const reqBody = {
      access_key: data.form_access_key,
    };

    return fetch("/api/users/tokens", {
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((resp) => {
        if (!resp.ok) {
          if (resp.status === 401) {
            throw new Error("Невалиден код за достъп.");
          }

          throw new Error(
            "Неочаквана грешка при изпращане на заявката. Моля, опитайте отново или се свържете с нас за съдействие."
          );
        }

        setFormSuccessMessage("Успешно отваряне на подаръка!");
        window.location.pathname = `/api/qr-codes/${qrCodeID}/content`;
      })
      .catch((err) => {
        setFormSubmitError(err?.message || err || "Неочаквана грешка.");
      });
  };

  useEffect(() => {
    if (shouldAttemptAutoSubmit && qrCodeID && qrCodeID.startsWith("ac")) {
      const suffix = qrCodeID.slice(2);
      setValue("form_access_key", suffix);
      handleSubmit(onSubmit)();
    }
  }, [qrCodeID, shouldAttemptAutoSubmit]);

  return (
    <>
      <Helmet>
        <title>QR подаръци - страница за достъп - Теонибайт ЕООД</title>

        <meta
          name="description"
          content="Иновативни идеи за подаръци с QR кодове. Персонализирани подаръци за всеки повод и иновативни продукти, с които да зарадвате вашите близки."
        />
      </Helmet>

      <section
        className="section"
        style={{
          minHeight: "100vh",
          flexDirection: "column",
          gap: "48px",
          marginTop: "0px",
        }}
      >
        <Navigation withLogoLink isCentered />

        <div className="section-block access-form-section-block">
          <p className="section-subtext">
            Въведете вашия уникален код за достъп, за да отключите съдържанието
            на продукта.
          </p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row width-100">
              <div className="form-row-col width-100">
                <label htmlFor="form_access_key" className="vertical-alignment">
                  <Lock size={16} weight="bold" className="neutral-color" /> Код
                  за достъп{" "}
                  <Asterisk
                    size={16}
                    weight="bold"
                    className="form-label-asterisk"
                  />
                </label>
                {errors && errors.form_access_key && (
                  <span className="access-form-error-message">
                    {errors.form_access_key.message}
                  </span>
                )}
                <input
                  type="text"
                  id="form_access_key"
                  maxLength={64}
                  disabled={isSubmitting}
                  {...register("form_access_key", {
                    required: "Моля, въведете код за достъп.",
                  })}
                  style={{ width: "auto" }}
                />
              </div>
            </div>
            <div className="form-row width-100" style={{ paddingTop: "0px" }}>
              <div className="form-row-col width-100">
                {formSubmitError && (
                  <>
                    <span className="access-form-error-message">
                      {formSubmitError}
                    </span>
                    <br />
                  </>
                )}

                {formSuccessMessage && (
                  <>
                    <span className="form-success-message">
                      {formSuccessMessage}
                    </span>
                    <br />
                  </>
                )}

                {isSubmitting ? (
                  <div style={{ alignSelf: "center" }}>
                    <RotatingLines
                      visible={true}
                      height="48"
                      width="48"
                      strokeColor="#fff75e"
                      strokeWidth="5"
                      animationDuration="1"
                    />
                  </div>
                ) : (
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      setFormSubmitError(null);
                      setFormSuccessMessage(null);
                    }}
                    className="form-submit-button"
                  >
                    Отвори
                  </button>
                )}
              </div>
            </div>
          </form>

          <p className="section-subtext">
            Ако не намирате вашия код за достъп, моля, свържете се с нас за
            съдействие чрез линковете по-долу.
          </p>
        </div>
        <div style={{ marginTop: "auto" }}>
          <Footer />
        </div>
      </section>
    </>
  );
}

export default Access;
