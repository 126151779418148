import { useEffect, useState } from "react";
import "./Discovery.css";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";
import {
  FaThumbsDown,
  FaRegThumbsDown,
  FaThumbsUp,
  FaRegThumbsUp,
  FaQuestion,
} from "react-icons/fa6";
import Navigation from "./Navigation";
import { Helmet } from "react-helmet";
import { RotatingLines } from "react-loader-spinner";

const unauthorizedErrorMessage =
  "Нямате достъп до това съдържание. " +
  "Моля сканирайте QR кода и въведете вашия код за достъп. " +
  "Ако сте изгубили вашия код за достъп, моля свържете се с нас чрез линковете по-долу.";

function Discovery() {
  const params = useParams();
  const themeID = params.themeID;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  const [isLoading, setIsLoading] = useState(false);
  const [promoCodeBlockActive, setPromoCodeBlockActive] = useState(true);

  const [discovery, setDiscovery] = useState();

  const [discoverySeed, setDiscoverySeed] = useState();

  const [discoveriesStats, setDiscoveriesStats] = useState();

  const [error, setError] = useState();

  useEffect(() => {
    setIsLoading(true);

    fetch(`/api/themes/${themeID}/discoveries/next/seed`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(unauthorizedErrorMessage);
          } else if (response.status === 404) {
            return Promise.resolve({}); // No seed found
          }

          throw new Error("Неочаквана грешка при заявка към системата");
        }

        return response.json();
      })
      .then((data) => {
        setDiscoverySeed(data.seed);
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [themeID]);

  useEffect(() => {
    if (!discoverySeed) {
      return;
    }

    setIsLoading(true);

    fetch(`/api/themes/${themeID}/discoveries/next`)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Няма открито съдържание за деня");
          } else if (response.status === 401) {
            throw new Error(unauthorizedErrorMessage);
          } else if (response.status === 422) {
            throw new Error(
              "Необходимо е да изберете карта, моля рестартирайте страницата"
            );
          }

          throw new Error("Неочаквана грешка при заявка към системата");
        }

        return response.json();
      })
      .then((data) => {
        setDiscovery(data);
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [themeID, discoverySeed]);

  useEffect(() => {
    if (!discoverySeed) {
      return;
    }

    if (!discovery) {
      return;
    }

    fetch(`/api/themes/${themeID}/discoveries/stats`)
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Неочаквана грешка при заявка към системата за статистика на съдържанието"
          );
          return;
        }

        return response.json();
      })
      .then((data) => {
        setDiscoveriesStats(data);
      });
  }, [themeID, discoverySeed, discovery]);

  const handleCardClick = (index) => {
    setIsLoading(true);

    fetch(`/api/themes/${themeID}/discoveries/next/seed`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ seed: index }),
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(unauthorizedErrorMessage);
          }

          throw new Error(
            "Неочаквана грешка, моля опитайте да рестартирате страницата"
          );
        }

        setDiscoverySeed(index);
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleThumbsUp = () => {
    return fetch(`/api/themes/${themeID}/discoveries/${discovery.id}/like`, {
      method: "PUT",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Неочаквана грешка, моля рефрешнете страницата");
        }

        setDiscovery({
          ...discovery,
          user_metadata: {
            ...discovery.user_metadata,
            like_exists: true,
            dislike_exists: false,
          },
        });
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      });
  };

  const handleThumbsDown = () => {
    return fetch(`/api/themes/${themeID}/discoveries/${discovery.id}/dislike`, {
      method: "PUT",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Неочаквана грешка, моля рефрешнете страницата");
        }

        setDiscovery({
          ...discovery,
          user_metadata: {
            ...discovery.user_metadata,
            dislike_exists: true,
            like_exists: false,
          },
        });
      })
      .catch((err) => {
        setError(err?.message || "Неочаквана грешка");
      });
  };

  let mainElement = <div className="discovery-section-block"></div>;
  if (isLoading) {
    mainElement = (
      <div className="discovery-section-block">
        <RotatingLines
          visible={true}
          height="64"
          width="64"
          strokeColor="#fff75e"
          strokeWidth="5"
          animationDuration="1"
        />
      </div>
    );
  } else if (error) {
    mainElement = (
      <div className="discovery-section-block">
        <span className="discovery-error">{error}</span>
      </div>
    );
  } else if (!discoverySeed) {
    mainElement = (
      <div className="discovery-section-block">
        <h2 className="discovery-header">{getCardPickerHeading(themeID)}</h2>

        <div className="discovery-cards-block">
          <div className="discovery-card" onClick={() => handleCardClick(1)}>
            <FaQuestion className="discovery-card-icon" />
          </div>
          <div className="discovery-card" onClick={() => handleCardClick(2)}>
            <FaQuestion className="discovery-card-icon" />
          </div>
          <div className="discovery-card" onClick={() => handleCardClick(3)}>
            <FaQuestion className="discovery-card-icon" />
          </div>
        </div>
      </div>
    );
  } else if (discovery) {
    mainElement = (
      <div className="discovery-section-block">
        <div className="discovery-header-block">
          <h2 className="discovery-header">
            {getHeaderFromType(discovery.type, setError)}
          </h2>
          <h6 className="discovery-subheader">{formattedDate}</h6>
        </div>

        {discovery?.warning && (
          <p className="discovery-warning">{discovery.warning}</p>
        )}

        <div className="discovery-content-text-block">
          {discovery?.user_metadata?.dislike_exists ? (
            <FaThumbsDown
              className="discovery-content-text-thumbs-down"
              onClick={handleThumbsDown}
            />
          ) : (
            <FaRegThumbsDown
              className="discovery-content-text-thumbs-down"
              onClick={handleThumbsDown}
            />
          )}

          <p className="discovery-content-text">{discovery.content}</p>

          {discovery?.user_metadata?.like_exists ? (
            <FaThumbsUp
              className="discovery-content-text-thumbs-up"
              onClick={handleThumbsUp}
            />
          ) : (
            <FaRegThumbsUp
              className="discovery-content-text-thumbs-up"
              onClick={handleThumbsUp}
            />
          )}
        </div>

        {discovery.source && (
          <div className="discovery-content-source">
            {discovery.source.link ? (
              <>
                <p className="discovery-content-source-text">Източник:</p>
                <NavLink
                  to={discovery.source.link}
                  target="_blank"
                  className="discovery-content-source-link"
                >
                  {discovery.source.text}
                </NavLink>
              </>
            ) : (
              <p className="discovery-content-source-text">
                Източник: {discovery.source.text}
              </p>
            )}
          </div>
        )}

        {discoveriesStats &&
          getStatsSection(discovery.type, discoveriesStats, setError)}
      </div>
    );
  }

  return (
    <section className="section discovery-section">
      <Helmet>
        <title>QR подаръци - съдържание на деня - Теонибайт ЕООД</title>

        <meta
          name="description"
          content="Иновативни ключодържатели и чаши с уникални дизайни на животни, които носят скрити изненади. Сканирайте QR кода за ежедневна порция късмет или любопитен факт!"
        />
      </Helmet>

      <Navigation withLogoLink isCentered />

      {mainElement}

      {discoveriesStats?.promo_codes &&
        getPromoCodesSection(
          discoveriesStats.promo_codes,
          promoCodeBlockActive,
          setPromoCodeBlockActive
        )}

      <div className="discovery-footer-block">
        <Footer />
      </div>
    </section>
  );
}

function getHeaderFromType(discoveryType, setError) {
  switch (discoveryType) {
    case "joke":
      return "Виц на Деня";
    case "fortune":
      return "Късметче на деня";
    case "fact":
      return "Дневна доза знание";
    case "thought":
      return "Мисъл на Деня";
    default:
      setError("Непознат тип съдържание");
  }
}

function getStatsSection(discoveryType, discoveriesStats, setError) {
  let heading;
  switch (discoveryType) {
    case "fortune":
      heading = `Уау! Вече имаш колекция от ${
        discoveriesStats.access_events_count
      } ${
        discoveriesStats.access_events_count != 1 ? "късметчета" : "късметче"
      }! 
        В класацията ни си на ${
          discoveriesStats.access_events_rank
        } място сред любителите на късметчета!`;

      return <h6 className="discovery-extra-subheader">{heading}</h6>;
    case "fact":
      heading = `Любопитството ти те доведе до ${
        discoveriesStats.access_events_count
      } ${
        discoveriesStats.access_events_count != 1
          ? "интересни факта"
          : "интересен факт"
      }! 
        В момента си на ${
          discoveriesStats.access_events_rank
        } място сред най-любознателните!`;

      return <h6 className="discovery-extra-subheader">{heading}</h6>;
    default:
      setError("Непознат тип съдържание");

      return null;
  }
}

function getPromoCodesSection(
  promoCodes,
  promoCodeBlockActive,
  setPromoCodeBlockActive
) {
  if (!promoCodes || promoCodes.length === 0) {
    return null;
  }

  return (
    <div
      className={
        "discovery-promo-codes-block" + (promoCodeBlockActive ? " active" : "")
      }
    >
      <span
        className="discovery-promo-codes-block-close-button"
        onClick={() => setPromoCodeBlockActive(false)}
      >
        ✖
      </span>

      <h4 className="discovery-promo-code-heading">ЧЕСТИТО!</h4>

      <p>
        Ти си част от най-активните ни потребители. Наслади се на{" "}
        {promoCodes.length > 1
          ? "специални промо кодове"
          : "специален промо код"}{" "}
        от {promoCodes.length > 1 ? "нашите партньори" : "наш партньор"}:
      </p>

      <ul className="discovery-promo-codes-list">
        {promoCodes.map((promoCode) => (
          <li key={promoCode.code} className="discovery-promo-code">
            <a
              href={promoCode.partner_url}
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link discovery-promo-code-link"
            >
              {promoCode.partner_name}
            </a>

            <p>
              -{promoCode.discount_percentage}% с код {promoCode.code}
            </p>

            <button
              className="discovery-promo-code-button"
              onClick={() => {
                window.open(
                  promoCode.partner_url,
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              Към сайта
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}

function getCardPickerHeading(themeID) {
  // FIXME - this should be coming from the backend
  switch (themeID) {
    case "t1l5g8":
      return "Избери твоето късметче на деня";
    case "b4x7m3":
      return "Избери твоята дневна доза знание";
    default:
      return "Избери твоята карта на деня";
  }
}

export default Discovery;
